<template>
    <section class="slider-small">
        <div class="slider-small-inner-content"
            :style="Image">
            <h1 class="text-capitalize text-center">{{Title}}</h1>
            <p>{{Subtitle}}</p>
        </div>
    </section>
</template>

<script>
    export default {
        name: "PageBanner",
        data() {
            return {
                Title: this.$route.name+" Peeters Interiors",
                Subtitle: "Interieur advies & uitvoering voor particulieren en bedrijven.",
                Image: "background-image: url('https://images.pexels.com/photos/1109541/pexels-photo-1109541.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940')",
            }
        }
    }
</script>